import React from "react";
import Home from './home/home';
import Donate from './Donate/donate';
import News from './news/news';
import EventPage from "./Event/eventpage";
import Contact from "./contact/contactform";
import Purpose from "./purpose/purpose";
// import FlashNews from "./home/flashnews";
// import PopupModal from "./popup/PopupModal";








const Main = () => {
    return(
        <>
        
        <Home/>
        {/* <FlashNews/> */}
        <Purpose/>
        <News/>
        <EventPage/>
        <Donate/>
        <Contact/>
        {/* <PopupModal/> */}
        
        

        

        
        
       
        
        
        
        </>
    );
}
export default Main;