import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { TbWorld } from 'react-icons/tb';
// import { MdGTranslate } from "react-icons/md";
import Logo from '../header/image/logo.jpeg';
import Logo2 from '../header/image/WTS-Full-Logo-WT.png';
import { FaBars, FaTimes, FaChevronDown } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isHomePage, setIsHomePage] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setIsHomePage(location.pathname === "/");

    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const switchLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'TA' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const navLinks = [
    { to: "/", text: t('header.home'), id: "" },
    {to: "/about", text: t('header.aboutus'), id: ""},
    { to: "/committe", text:t('header.members') , id: "" },
    { to: "/invitation", text: t('header.invitation'), id: "" },
    { to: "/eventhome", text:t('header.festival'), id: "" },
    { to: "/publish", text:t('header.publish') , id: "" },
    // { to: "/blog", text: t('header.newssection'), id: "" },
    { to: "/channels", text: t('header.channel'), id: "" },
   
    
    // { to: "/channels", text:t('header.channel') , id: "" },
  ];

  const actionLinks = [
    // { to: "/donatenow", text: t('header.donate'), className: "text-yellow-300 lg:ml-6 mt-3", id: "donate" },
    { to: "/loginform", text: t('header.login'), className: "text-white lg:ml-5 mt-3" },
    { to: "/membership", text: t('header.join'), className: "px-8 py-2 w-24 mt-3 rounded-full text-yellow-300 border border-white mt-1 lg:ml-6" }
  ];

  // const getNavbarBackground = () => {
  //   if (isScrolled) {
  //     return 'bg-blue-950';
  //   } else {
  //     return 'bg-transparent';
  //   }
  // };

  const getNavbarBackground = () => {
    const pagesWithBackgroundImage = ["/", "/vision", "/about", "/eventhome", "/committe", "/publish", "/channels"];
  
    if (isScrolled) {
      return 'bg-blue-950';
    } else if (pagesWithBackgroundImage.includes(location.pathname)) {
      return 'bg-transparent';
    } else {
      return 'bg-blue-950'; // Set this to the desired red color
    }
  };

  const handleSubLinkClick = (to) => {
    setIsDropdownOpen(false);
    setIsMenuOpen(false);
    navigate(to);
  };

  return (
    <>
      <div className={`navbar text-white big-screen:py-0 mb-8 z-30 fixed w-full transition-all duration-300 ${getNavbarBackground()}`}>
        <div className="flex justify-between items-center mb-2 mx-4 head-home:my-2 medium-screen:mx-32 head-home:mx-20 lg:mx-12 big-screen:mx-24">
        <div className="flex items-center">
            <Link to="/" ><img src={Logo} alt="logo" className="mt-2 mx-0 mr-2 lg:mr-2 big-screen:w-[75px] 
            big-screen:h-[75px] sm:w-[55px] rounded-full sm:[55px] w-[55px] h-[55px]" /></Link>
             <Link to="/" ><img src={Logo2} alt="logo" className="mt-2 mx-0 mr-2 lg:mr-2 big-screen:w-[185px] 
            big-screen:h-[75px]  rounded-full sm:[55px] w-[170px] h-[55px]" /></Link>
          </div>

          <ul className="hidden xl:flex lg:mt-3 big-screen:gap-20 lg:gap-5 lg:pl-12 list-none">
            {navLinks.map((link, index) => (
              <li key={index} className="relative">
                {link.isDropdown ? (
                  <>
                    <a
                      href="#"
                      className="text-white big-screen:text-xl no-underline flex items-center text-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleDropdown();
                      }}
                    >
                      {link.text}
                      <FaChevronDown className="ml-2" />
                    </a>
                    {isDropdownOpen && (
                      <ul className="absolute bg-[#281E5D] text-white mt-2 py-2 w-48 shadow-lg rounded-md">
                        {link.subLinks.map((subLink, subIndex) => (
                          <li key={subIndex}>
                            <a
                              href={subLink.to}
                              className="block px-4 py-2 text-white text-sm hover:bg-blue-800"
                              onClick={(e) => {
                                e.preventDefault();
                                handleSubLinkClick(subLink.to);
                              }}
                            >
                              {subLink.text}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  <Link
                    to={link.to}
                    className="text-white big-screen:text-xl no-underline flex items-center text-sm"
                    onClick={() => setIsMenuOpen(false)} // Close menu on link click
                  >
                    {link.text}
                  </Link>
                )}
              </li>
            ))}
          </ul>

          <div className="hidden xl:flex items-center big-screen:text-big-screen text-base ml-24">
            <Link className="mt-3 "><i  onClick={switchLanguage}><TbWorld /></i></Link>
           {/* <Link className="mt-2 text-xl "><i  onClick={switchLanguage}><MdGTranslate  /></i></Link>*/}
            
            {actionLinks.map((link, index) => (
              <Link key={index} to={link.to} className={link.className}>
                {link.text}
              </Link>
            ))}
          </div>


          <div className="xl:hidden">
            <button onClick={toggleMenu} className="text-white pt-3 text-2xl focus:outline-none">
            <FaBars className="text-big-screen" />
            </button> 
          </div>
        </div>

        {isMenuOpen && (
          <div className="xl:hidden fixed inset-0 z-40">
            <ul className={`fixed top-0 right-8 left-0  h-full w-[86%] bg-black bg-opacity-90 text-white  py-4 px-2 overflow-y-auto`}>
              <div className="flex justify-between pb-4 items-center px-2">
                <Link to="/" className="flex items-center">
                  <img src={Logo} alt="logo" className="w-[65px] sm:h-[45px]  md:w-[45px] md:h-[45px] rounded-full mt-2" />
                </Link>
                <Link to="/" ><img src={Logo2} alt="logo" className="mt-2 mx-0 mr-32  md:mr-96 big-screen:w-[55px] 
            big-screen:h-[55px]  rounded-full  w-[150px] h-[45px]" /></Link>
                
                <button onClick={toggleMenu} className="text-white  text-2xl">
                  <FaTimes />
                </button>
              </div>
              {navLinks.map((link, index) => (
                <li key={index} className="relative  pl-4">
                  {link.isDropdown ? (
                    <>
                      <a
                        href="#"
                        className="text-white no-underline flex items-center text-lg py-2 border-b border-gray-600"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleDropdown();
                        }}
                      >
                        {link.text}
                        <FaChevronDown className="ml-2" />
                      </a>
                      {isDropdownOpen && (
                        <ul className="mt-2 bg-gray-800 rounded shadow-lg">
                          {link.subLinks.map((subLink, subIndex) => (
                            <li key={subIndex}>
                              <a
                                href={subLink.to}
                                className="block px-4 py-2 text-white text-lg hover:bg-gray-700"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleSubLinkClick(subLink.to);
                                }}
                              >
                                {subLink.text}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  ) : (
                    <Link
                      to={link.to}
                      className="text-white no-underline flex items-center text-lg py-2 border-b border-gray-600"
                      onClick={() => setIsMenuOpen(false)} // Close menu on link click
                    >
                      {link.text}
                    </Link>
                  )}
                </li>
              ))}
              <Link>
              <i className="mt-2 ml-4 flex items-center text-lg py-2 border-b border-gray-600" onClick={switchLanguage}><TbWorld  /></i>
              </Link> 
                
              {actionLinks.map((link, index) => (
                <li key={index} className='pl-4'>
                  <Link to={link.to} className={`${link.className} flex items-center text-lg py-2 border-b border-gray-600`}>
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;



