// import React from 'react';
// import { useTranslation } from 'react-i18next';

// const MyComponent = () => {
//   const { t } = useTranslation();

//   return (
//     <div className='flex flex-col items-center p-4 xl:pt-32 sm:p-8 md:p-12 2xl:p-20 '>
//       {/* <h1 className="text-center text-2xl sm:text-3xl md:text-4xl xl:text-5xl xl:font-medium 2xl:text-5xl 2xl:leading-normal font-medium mb-6 sm:mb-6 md:mb-8 leading-snug xl:leading-snug md:leading-normal">
//         {t('structure.subtitle')}
//       </h1> */}

//       <div className="flex flex-col md:flex-row items-center justify-center w-full">
//         <div className="w-full md:w-3/5 p-2 2xl:p-6 xl:p-8 ">
//           <div className="mb-6 md:mb-8 mt-4 xl:mb-8 md:mt-0">
//             <h2 className="text-center text-xl sm:text-2xl md:text-3xl 2xl:text-4xl xl:tracking-wider font-semibold 2xl:mb-6 mb-4 md:mb-4">
//               {t('structure.structure')}
//             </h2>
//             <p className="text-center text-black-300 text-sm sm:text-base xl:text-sm 2xl:text-lg xl:tracking-wider 2xl:leading-relaxed leading-relaxed xl:leading-relaxed lg:pb-6 md:pb-8">
//               {t('banner.registernumber')}
//             </p>
//             <p className="text-center text-black-300 text-sm sm:text-base xl:text-sm 2xl:text-lg xl:tracking-wider 2xl:leading-relaxed leading-relaxed xl:leading-relaxed lg:pb-6 md:pb-8">
//               {t('structure.p')}
//             </p>
//           </div>

//           <div>
//             <h2 className="text-center text-xl sm:text-2xl md:text-3xl 2xl:text-4xl xl:tracking-wider font-semibold 2xl:mb-6 mb-2 md:mb-4">
//               {t('structure.visions')}
//             </h2>
//             {Array.from({ length: 10 }, (_, i) => (
//               <div key={i} className="bg-white p-6 rounded-lg shadow-md w-full mb-4 transform transition-transform duration-300 hover:scale-105">
//                 <p className="text-start text-black-300 text-sm sm:text-base leading-relaxed xl:text-lg xl:tracking-wider 2xl:text-lg 2xl:leading-relaxed xl:leading-relaxed mt-4">
//                   &#9827; {t(`structure.p${i + 1}`)}
//                 </p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MyComponent;


// import React from 'react';
// import image from './images/valluvar.webp';
// import reward from './images/reward.png';

// function BeautifulDesign() {
//   return (
//     <>
//       <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-12">
//         {/* Heading */}
//         <h1 className="text-4xl font-bold mb-12 text-center text-gray-800">
//           Mission & Vision
//         </h1>

//         {/* Row with two columns without card structure */}
//         <div className="max-w-7xl w-full grid grid-cols-1 md:grid-cols-2 gap-8">
//           {/* Left Side - Image */}
//           <div className="w-full">
//             <img
//               src={image}
//               alt="Valluvar"
//               className="w-full h-full object-cover"
//             />
//           </div>

//           {/* Right Side - Content */}
//           <div className="w-full p-8 ">
//             {/* <h2 className="text-3xl font-semibold mb-6 text-gray-800">
//               Content Heading
//             </h2> */}

//             {/* Content with points and icons */}
//             <ul className="space-y-4">
//               <li className="flex items-center text-gray-600">
//                 <img src={reward} alt="reward" className="w-[3%] h-[3%] mr-2" />
//                 அழகான மற்றும் எளிமையான வடிவமைப்பு
//               </li>
//               <li className="flex items-center text-gray-600">
//                 <img src={reward} alt="reward" className="w-[3%] h-[3%] mr-2" />
//                 Responsive Design (Mobile & Desktop)
//               </li>
//               <li className="flex items-center text-gray-600">
//                 <img src={reward} alt="reward" className="w-[3%] h-[3%] mr-2" />
//                 Tailwind CSS மூலம் எளிய Customization
//               </li>
//               <li className="flex items-center text-gray-600">
//                 <img src={reward} alt="reward" className="w-[3%] h-[3%] mr-2" />
//                 ReactJS மூலம் Dynamic Content Rendering
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default BeautifulDesign;

import React from 'react';
import { useTranslation } from 'react-i18next';
import image from './images/valluvar.webp';
import reward from './images/reward.png';

function BeautifulDesign() {

  const { t } = useTranslation();

  return (
    <>
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center px-4 md:px-12 py-12">
    <div className="text-left pl-12 uppercase animate-text-pop-up-top" style={{ textShadow: "2px 8px 2px rgba(0, 0, 0, 0.3)" }}>
      <strong className="block text-[40px] leading-[80px] text-blue-950 font-poppins font-black"
      >Vision & Mission</strong>
    </div>

      {/* Grid Section */}
      <div className="max-w-7xl w-full grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left Side - Image */}
        <div className="w-full mt-16"> 
          <img
            src={image}
            alt="Valluvar"
            className="object-cover rounded-lg shadow-lg"
          />
        </div>

        {/* Right Side - Content */}
        <div className="w-full p-6 md:p-8">
          <h2 className="text-2xl md:text-3xl font-semibold mb-6 text-gray-800">
            {t('structure.structure')}
          </h2>

          {/* Responsive List Items */}
          <ul className="space-y-4">
            {Array.from({ length: 10 }, (_, i) => (
              <li key={i} className="flex items-center text-gray-600">
                <img src={reward} alt="reward" className="w-4 h-4 md:w-4 md:h-4 mr-4" />
                <span className="text-sm md:text-base">{t(`structure.p${i + 1}`)}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    </>
  );
}

export default BeautifulDesign;












