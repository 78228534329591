import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './i18n';
import "@fontsource/mukta-malar/500.css";
import './index.css';
import Main from './Main';

import Header from './header/header';
import Footer from './footer/footer';


import DonateNow from './Donateform/donatenow';
import Page from './cms/cms';
import Donate from './Donate/donate';
import Home from './home/home';
import Contact from './contact/contactform';
import Details from './purpose/details.js';
import Vision from './Multiple/vision/vision.js';
import Newssection from './Multiple/newssection/newssection.js';
import Readmorenews from './news/readmorenews.js';
import TeamSection from './Multiple/structure/members.js';
import EventHome from './Multiple/festival/eventhome.js';
import AllNews from './news/allnews.js';
import Blog from './Multiple/newssection/blog.js';
import Invitation from './Multiple/invitation/invitation.js';
import Contactus from './contact/contactus.js';
import Membership from './Membership/membership.js';
import LoginForm from './loginform/loginform.js';
import GalleryHome from './Multiple/eventfestival/galleryhome.js';
import AdminLoginForm from './adminlogin/adminlogin.js';
import AdminRegister from './adminlogin/adminregister.js';
import ScrollToTop from './constant/scrooltotop.js';
import Publish from './Multiple/publish/publish.js';
import Channels from './Multiple/channels/channels.js';
import Committe from './Multiple/committe/committe.js';
import About from './Multiple/structure/about.js';







function App() {
  return (
    <>
      <Router>
        {/* <Header/> */}
        <ScrollToTop/>
        <Routes>
          <Route exact path='/' element={<Main />} />
          <Route path='/home' element={<Home/>} />
          <Route path='/donate' element={<Donate/>} />
          <Route path='/donatenow' element={<DonateNow/>} />
          <Route path='/contactform' element={<Contact/>} />
          <Route path='/vision' element={<Vision/>} />
          <Route path='/details' element={<Details/>} />
          <Route path='/newssection' element={<Newssection/>} />
          <Route path='/readmorenews' element={<Readmorenews/>} />
          <Route path='/members' element={<TeamSection/>} />
          <Route path='/eventhome' element={<EventHome/>} />
          <Route path='/galleryhome' element={<GalleryHome/>} />
          <Route path='/allnews' element={<AllNews/>} />
          <Route path='/blog' element={<Blog/>} />
          <Route path='/invitation' element={<Invitation/>} />
          <Route path='/contactus' element={<Contactus/>} />
          <Route path='/membership' element={<Membership/>} />
          <Route path='/loginform' element={<LoginForm/>} />
          <Route path='/cms' element={<Page/>} />
          <Route path='/adminlogin' element={<AdminLoginForm/>} />
          <Route path='/adminregister' element={<AdminRegister/>} />
          <Route path='/publish' element={<Publish/>} />
          <Route path='/channels' element={<Channels/>} />
          <Route path='/committe' element={<Committe/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/channels' element={<Channels/>} />
          
         </Routes>
        <Footer/>
      </Router>
      
    </>
  );
}
export default App;
