// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { FaArrowRight } from "react-icons/fa";
// import bannerImage from '../home/images/banner.png';
// import Header from '../header/header.js';
// import { useTranslation } from 'react-i18next';
// import image1 from '../home/images/thiruvalluvar.png';
// import image2 from '../home/images/my-image (1).png'; 
// import image3 from '../home/images/center-image.png'; 

// // aos
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// const Home = () => {
//   const { t } = useTranslation();

//   // Define images for the slideshow
//   const images = [image1, image2, image3];
  
//   // State to track the current image index
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);

//   const headings = [
//     { text: t('banner.title1'), className: 'md:leading-snug very-screen:text-[570%] small-screen:text-[100px] medium-screen:text-[100px]  big-screen:leading-loose head-home:text-[400%] text-3xl lg:tracking-wider big-screen:text-[110px] xl:text-[330%]  lg:leading-snug sm:text-6xl lg:text-[350%]  font-bold' },
//     { text: t('banner.title2'), className: 'md:leading-snug very-screen:text-[570%] small-screen:text-[100px] medium-screen:text-[100px] text-3xl sm:text-6xl head-home:text-[400%] lg:tracking-wider  big-screen:text-[110px] lg:text-[350%] xl:text-[330%] border-b-2 big-screen:border-b-4 font-bold border-[#FFFFFF] big-screen:pb-24 pb-4 md:pb-14 iphonexr:pb-8' }
//   ];

//   const buttonLinks = [
//     { text: t('banner.membershipRegistration'), className: 'rounded-full  bg-[#281E5D] big-screen:text-2xl big-screen:px-8 big-screen:py-6 px-6 py-4', to: '/membership' },
//     { text: t('header.donate'), className: 'flex items-center font-base 2xl:text-lg  big-screen:text-3xl text-white-900', to: '/donatenow' }
//     // { to: "/donatenow", text: t('header.donate'), className: "text-white-300 lg:ml-6 mt-3", id: "donate" }
//   ];

//   useEffect(() => {
//     AOS.init();
    
//     // Set interval to change the image every 3 seconds (3000ms)
//     const interval = setInterval(() => {
//       setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 3000); // Change the time interval as needed

//     // Cleanup interval on component unmount
//     return () => clearInterval(interval);
//   }, [images.length]);

//   // Trigger AOS refresh every time the image changes
//   useEffect(() => {
//     AOS.refresh();
//   }, [currentImageIndex]);

  

//   return (
//     <>
//     <div id='home' className="relative w-[100%] min-h-screen bg-fixed bg-cover item-center place-items-center text-white" style={{ backgroundImage: `url(${bannerImage})` }}>
//       <Header />
//       <div className="fixed z-0 small-screen:pt-96 medium-screen:mx-32 flex flex-col xl:pt-[20%] pt-40  big-screen:pt-[28rem] big-screen:mx-32
//        head-home:gap-32 xl:gap-[10%] head-home:mx-20 very-screen:pt-[21%] medium-screen:pt-[19%] head-home:pt-[21%] big-screen:gap-44 mx-4 lg:mx-14 md:pt-44 md:flex-row gap-20">
//         <div>
//         <div data-aos="fade-right" data-aos-delay="30" data-aos-duration="2000">

//           <div className="flex flex-col text-start text-[#F9FAFB] iphonexr:gap-3 iphonexr-pb-8">
//             {headings.map((heading, index) => (
//               <span key={index} className={heading.className}>
//                 {heading.text}
//               </span>
//             ))}
//           </div>
//           </div>
            
          
//           <div className='mt-8 md:mt-14 big-screen:mb-12 big-screen:mt-28 flex flex-col big-screen:text-xl md:flex-row gap-4 md:gap-10 items-center space-x-0 md:space-x-4 iphonexr:gap-10'>
//             {buttonLinks.map((link, index) => (
//               <Link key={index} to={link.to} className={`${link.className}`}>
//                 {link.text}
//                 {link.to === '/donatenow' && (
//                   <i className='ml-2 font-light'>
//                     <FaArrowRight />
//                   </i>
//                 )}
//               </Link>
//             ))}
//           </div>
//         </div>
//         <div className="relative" style={{ marginTop: '-100px'}}>
//            <div 
//             data-aos="zoom-in" 
//             data-aos-delay="30" 
//             data-aos-duration="2000"
//             key={currentImageIndex} // Important for AOS to recognize image changes
//           >
//             <img src={images[currentImageIndex]} alt='slideshow' className='h-[450px]' />
//           </div> 
//         </div>
//       </div>
//     </div>
//     </>
//   );
// };

// export default Home;


// import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { FaArrowRight } from "react-icons/fa";
// import { Carousel } from 'react-responsive-carousel';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel CSS
// import bannerImage1 from '../home/images/banners.png';
// import bannerImage2 from '../home/images/1.jpg';
// import bannerImage3 from '../home/images/2.jpg';

// import Header from '../header/header.js';
// import { useTranslation } from 'react-i18next';

// // aos
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// const Home = () => {
//   const { t } = useTranslation();

//   const headings = [
//     { text: t('banner.title1'), className: 'md:leading-snug very-screen:text-[570%] small-screen:text-[100px] medium-screen:text-[100px] big-screen:leading-loose head-home:text-[300%] text-3xl lg:tracking-wider big-screen:text-[110px] xl:text-[330%] lg:leading-snug sm:text-6xl lg:text-[350%] font-bold' },
//     { text: t('banner.title2'), className: 'md:leading-snug very-screen:text-[570%] small-screen:text-[100px] medium-screen:text-[100px] text-3xl sm:text-6xl head-home:text-[300%] lg:tracking-wider big-screen:text-[110px] lg:text-[350%] xl:text-[330%] border-b-2 big-screen:border-b-4 font-bold border-[#FFFFFF] big-screen:pb-24 pb-4 md:pb-14 iphonexr:pb-8' }
//   ];

//   const buttonLinks = [
//     { text: t('banner.membershipRegistration'), className: 'rounded-full bg-[#281E5D] big-screen:text-2xl big-screen:px-8 big-screen:py-6 px-6 py-4', to: '/membership', aos: 'fade-left', aosDelay: '500' },
//     { text: t('header.donate'), className: 'flex items-center font-base 2xl:text-lg big-screen:text-3xl text-white-900', to: '/donatenow', aos: 'fade-right', aosDelay: '700' }
//   ];

//   useEffect(() => {
//     AOS.init();
//   }, []);

//   return (
//     <>
//       <div id='home' className="relative w-[100%] min-h-screen bg-fixed bg-cover item-center place-items-center text-white">
//         <Header />

//         {/* Carousel Section */}
//         <div className="relative">
//           <Carousel
//             autoPlay
//             infiniteLoop
//             interval={3000}
//             showThumbs={false}
//             showArrows={false}
//             showStatus={false}
//             className="relative w-[100%] h-[90vh] md:h-[80vh] lg:h-[95vh]"  // Increased height for responsiveness
//           >
//             <div>
//               <img src={bannerImage1} alt="Banner 1" className="object-cover w-[100%] h-[90vh] md:h-[80vh] lg:h-[95vh]" />
//             </div>
//             <div>
//               <img src={bannerImage2} alt="Banner 2" className="object-cover w-[100%] h-[90vh] md:h-[80vh] lg:h-[95vh]" />
//             </div>
//             <div>
//               <img src={bannerImage3} alt="Banner 3" className="object-cover w-[100%] h-[90vh] md:h-[80vh] lg:h-[95vh]" />
//             </div>
//           </Carousel>

      
//           <div className="absolute top-[10%] left-0 w-full h-full flex flex-col justify-center items-center z-10" >  
//             <div>
//               <div className="flex flex-col text-center text-[#F9FAFB] iphonexr:gap-3">
//                 {headings.map((heading, index) => (
//                   <span key={index} className={heading.className}>
//                     {heading.text}
//                   </span>
//                 ))}
//               </div>
//             </div>

//             {/* Animated Buttons */}
//             <div className='mt-8 md:mt-14 flex flex-col big-screen:text-xl md:flex-row gap-4 md:gap-10 items-center'>
//               {buttonLinks.map((link, index) => (
//                 <Link
//                   key={index}
//                   to={link.to}
//                   className={`${link.className}`}
//                   // data-aos={link.aos}
//                   // data-aos-delay={link.aosDelay}
//                 >
//                   {link.text}
//                   {link.to === '/donatenow' && (
//                     <i className='ml-2 font-light'>
//                       <FaArrowRight />
//                     </i>
//                   )}
//                 </Link>
//               ))}
//             </div>
//           </div>
//         </div>

//       </div>
//     </>
//   );
// };

// export default Home;

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import logo from '../home/images/banners.png';
import bannerImage1 from '../home/images/mamallapuram.jpeg';
import bannerImage2 from '../home/images/indonasia-tiruvalluvar1.jpeg';
import bannerImage3 from '../home/images/cholan.jpg';
import bannerImage4 from '../home/images/thanjai.jpg';
import bannerImage5 from '../home/images/banimg1.jpeg';
import Header from '../header/header.js';
import { useTranslation } from 'react-i18next';

// aos
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
  const { t } = useTranslation();

  const headings = [
    { 
      text: t('banner.title1'), 
      className: 'md:text-4xl lg:text-5xl xl:text-6xl text-3xl leading-tight font-bold' 
    },
    { 
      text: t('banner.title2'), 
      className: 'md:text-4xl lg:text-5xl xl:text-6xl text-3xl leading-tight font-bold border-b-2 border-white pb-4 lg:pb-8' 
    }
  ];

  const buttonLinks = [
    { text: t('banner.membershipRegistration'), className: 'rounded-full bg-[#281E5D] big-screen:text-2xl big-screen:px-8 big-screen:py-6 px-6 py-4', to: '/membership', aos: 'fade-left', aosDelay: '500' },
    { text: t('header.donate'), className: 'flex items-center font-base 2xl:text-lg big-screen:text-3xl text-white-900', to: '/donatenow', aos: 'fade-right', aosDelay: '700' }
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div id='home' className="relative w-[100%] h-[100vh] bg-fixed bg-cover item-center place-items-center text-white">
        <Header />

        {/* Carousel Section */}
        <div className="relative">
          <Carousel
            autoPlay
            infiniteLoop
            interval={3000}
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            className="relative w-[100%] h-[95vh] md:h-[85vh] lg:h-[100vh]"  
          >
            
            <div>
              <img src={logo} alt="Banner" className="object-cover w-[100%] h-[95vh] md:h-[85vh] lg:h-[100vh]" />
            </div>
                   
            <div>
              <img src={bannerImage1} alt="Banner 1" className="object-cover w-[100%] h-[95vh] md:h-[85vh] lg:h-[100vh]" />
            </div>
            <div>
              <img src={bannerImage2} alt="Banner 2" className="object-cover w-[100%] h-[95vh] md:h-[85vh] lg:h-[100vh]" />
            </div>
            {/* <div>
              <img src={bannerImage3} alt="Banner 3" className="object-cover w-[100%] h-[95vh] md:h-[85vh] lg:h-[100vh]" />
            </div> */}
            <div className="relative">
  <img
    src={bannerImage3}
    alt="Banner 3"
    className="object-cover w-[100%] h-[95vh] md:h-[85vh] lg:h-[100vh]"
  />
  <div className="absolute inset-0 bg-black opacity-50"></div>
</div>

            <div>
              <img src={bannerImage4} alt="Banner 4" className="object-cover w-[100%] h-[95vh] md:h-[85vh] lg:h-[100vh]" />
            </div>
            <div>
              <img src={bannerImage5} alt="Banner 5" className="object-cover w-[100%] h-[95vh] md:h-[85vh] lg:h-[100vh]" />
            </div>
          </Carousel>

          {/* Text and Buttons */}
          <div className="absolute top-[10%] left-0 w-full h-full flex flex-col justify-center items-center z-10">
            <div>
              <div className="flex flex-col text-center text-[#F9FAFB] iphonexr:gap-3">
                {headings.map((heading, index) => (
                  <span key={index} className={heading.className}>
                    {heading.text}
                  </span>
                ))}
              </div>
            </div>

            {/* Animated Buttons */}
            <div className='mt-8 md:mt-14 flex flex-col big-screen:text-xl md:flex-row gap-4 md:gap-10 items-center'>
              {buttonLinks.map((link, index) => (
                <Link
                  key={index}
                  to={link.to}
                  className={`${link.className}`}
                >
                  {link.text}
                  {link.to === '/donatenow' && (
                    <i className='ml-2 font-light'>
                      <FaArrowRight />
                    </i>
                  )}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

























