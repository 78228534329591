import React from 'react';
import s1 from './images/school1.jpeg';
import s2 from './images/one.jpeg';
import s3 from './images/school3.jpeg';
import abc from "./images/abc.jpg";
import bcd from "./images/bcd.jpg";
import culture from "./images/cultural-heritage.jpg";
import mp from "./images/MP-temples.jpg";
import un from "./images/unnamed.jpg";
import im from "./images/images.jpg";
import one from "./images/one.jpeg";

import five from "./images/dce.jpg";
import six from "./images/five.jpeg";
import mur from "./images/images (1).jpg";
import muru from "./images/murugan-temple-.webp";
import Channelsbanner from './channelsbanner';
import { useTranslation } from 'react-i18next';

const Channels = () => {
  const { t } = useTranslation();
  const images = [s1,s2,s3,mur,one, abc,muru, bcd,five, culture, mp,six,one, un, im];

  return (
    <>
    <Channelsbanner/>
    <div className="text-center py-8">
      <h1 className='text-3xl text-black-700 py-4'>{t('channels.title')}</h1>
  <p className="text-xl text-gray-700 max-w-[90%] sm:max-w-[80%] md:max-w-[70%] lg:max-w-[60%] mx-auto">
    {t('channels.description')}
  </p>
</div>

    <div className="site-footer-marquee-item flex items-center justify-between py-4 border-t  overflow-hidden">
      {/* Marquee image container */}
      <div className="media-image-container flex-shrink-0">
        <div className="media-image flex animate-scroll">
          {/* Render images and duplicate them for smooth scrolling */}
          {[...images, ...images].map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Media ${index + 1}`}
              className="h-40 w-40 object-cover mx-2 scrollimg" // Set height and width
            />
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default Channels;