// import React, { useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';

// // aos
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// const Contact = () => {
//     const { t } = useTranslation();
//     useEffect(() => {
//         AOS.init();
//     }, []);

//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         mobile: '',
//         subject: '',
//         message: ''
//     });

//     const [errors, setErrors] = useState({});
//     const [submitted, setSubmitted] = useState(false); // State to track submission status

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value
//         });

//         if (errors[name]) {
//             setErrors({
//                 ...errors,
//                 [name]: ''
//             });
//         }
//     };

//     const handleBlur = (e) => {
//         const { name, value } = e.target;
//         let error = '';

//         if (!value) {
//             switch (name) {
//                 case 'name':
//                     error = 'Name is required';
//                     break;
//                 case 'email':
//                     error = 'Email is required';
//                     break;
//                 case 'mobile':
//                     error = 'Your Mobile number is required';
//                     break;
//                 case 'subject':
//                     error = 'Subject is required';
//                     break;
//                 case 'message':
//                     error = 'Message is required';
//                     break;
//                 default:
//                     break;
//             }
//         }

//         setErrors({
//             ...errors,
//             [name]: error
//         });
//     };

//     const validate = () => {
//         const newErrors = {};

//         if (!formData.name) newErrors.name = 'Name is required';
//         if (!formData.email) {
//             newErrors.email = 'Email is required';
//         } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
//             newErrors.email = 'Email is invalid';
//         }
//         if (!formData.mobile) newErrors.mobile = 'Your Mobile number is required';
//         if (!formData.subject) newErrors.subject = 'Subject is required';
//         if (!formData.message) newErrors.message = 'Message is required';

//         return newErrors;
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const validationErrors = validate();
//         if (Object.keys(validationErrors).length === 0) {
//             try {
//                 const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact/contacts`, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json'
//                     },
//                     body: JSON.stringify(formData)
//                 });

//                 if (response.ok) {
//                     console.log('Form submitted successfully');
//                     setFormData({
//                         name: '',
//                         email: '',
//                         mobile: '',
//                         subject: '',
//                         message: ''
//                     });
//                     setErrors({});
//                     setSubmitted(true); // Update state to show success message
//                 } else {
//                     console.error('Error submitting the form');
//                 }
//             } catch (error) {
//                 console.error('Error:', error);
//             }
//         } else {
//             setErrors(validationErrors);
//         }
//     };

//     useEffect(() => {
//         if (submitted) {
//             const timer = setTimeout(() => {
//                 setSubmitted(false);
//             }, 3000);
//             return () => clearTimeout(timer);
//         }
//     }, [submitted]);

//     return (
//         <>
//             <div className="relative z-1 px-4 md:px-8 block sm:block xl:flex md:flex lg:flex py-8 flex-wrap bg-[#F9FAFB]">
//                 {/* <div className="flex-1 xl:my-20 xl:ml-8 my-10 lg:my-4 xl:mx-0  form-text:ml-20 xl:w-2/5 form-text:w-1/2">
//                     <div className="bg-gray-300 text-gray-900 py-1 px-2 rounded-md form-text:text-3xl inline-block text-sm">
//                         {t('contact.title')}
//                     </div>
//                     <p className="mt-8 flex flex-col text-black text-lg md:text-xl form-text:text-4xl form-text:font-medium form-text:leading-loose font-medium leading-7 md:leading-9">
                        
//                         {t('contact.description')}
//                     </p>
                  

//                 </div> */}
//                 <div className=" mb-12 lg:mt-10 xl:mt-20 lg:mx-16 xl:mx-0 form-text:mr-28 xl:w-3/5 form-text:w-1/2">
//                     {/* <div data-aos="fade-down" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000" data-aos-delay="30"> */}
//                         <div className="mb-6 md:mb-12">
//                             <h1 className="font-semibold text-3xl xl:text-5xl lg:mx-40 xl:mx-0 md:mx-28 md:text-4xl form-text:text-7xl">
//                             {t('contact.heading')}</h1>
//                         </div>
//                     {/* </div> */}
//                     <form onSubmit={handleSubmit}>
//                         <div>
//                             <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-16">
//                                 <div className="flex-1">
//                                     <label htmlFor="name" className="block mb-1 font-semibold text-lg md:text-lg form-text:text-3xl">Name</label>
//                                     <input
//                                         type="text"
//                                         id="name"
//                                         name="name"
//                                         placeholder="Enter your name"
//                                         value={formData.name}
//                                         onChange={handleChange}
//                                         onBlur={handleBlur}
//                                         className="w-full md:w-80 lg:w-96 xl:w-full p-2.5 form-text:h-20 form-text:rounded-xl form-text:w-full form-text:text-2xl form-text:p-3.5 rounded bg-[#F1F2F3]"
//                                     />
//                                     {errors.name && <span className="text-red-500 block mt-1">{errors.name}</span>}
//                                 </div>
//                                 <div className="flex-1 mb-6 md:mb-12">
//                                     <label htmlFor="email" className="block mb-1 font-semibold text-lg md:text-lg form-text:text-3xl">Email</label>
//                                     <input
//                                         type="email"
//                                         id="email"
//                                         name="email"
//                                         placeholder="Enter e-mail"
//                                         value={formData.email}
//                                         onChange={handleChange}
//                                         onBlur={handleBlur}
//                                         className="w-full md:w-80 lg:w-96 xl:w-full p-2.5 form-text:h-20 form-text:rounded-xl form-text:w-full form-text:p-3.5 form-text:text-2xl rounded bg-[#F1F2F3]"
//                                     />
//                                     {errors.email && <span className="text-red-500 block mt-1">{errors.email}</span>}
//                                 </div>
//                             </div>


//                             <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-16">
//                                 <div className="flex-1">
//                                     <label htmlFor="mobile" className="block mb-1 font-semibold text-lg md:text-lg form-text:text-3xl">Mobile Number</label>
//                                     <input
//                                         type="number"
//                                         id="mobile"
//                                         name="mobile"
//                                         placeholder="Enter Mobile Number"
//                                         value={formData.mobile}
//                                         onChange={handleChange}
//                                         onBlur={handleBlur}
//                                         className="w-full md:w-80 lg:w-96 xl:w-full p-2.5 form-text:h-20 form-text:rounded-xl form-text:w-full form-text:text-2xl form-text:p-3.5 rounded bg-[#F1F2F3]"
//                                     />
//                                     {errors.mobile && <span className="text-red-500 block mt-1">{errors.mobile}</span>}
//                                 </div>
//                                 <div className="flex-1 mb-6 md:mb-12">
//                                     <label htmlFor="subject" className="block mb-1 font-semibold text-lg md:text-lg form-text:text-3xl">Subject</label>
//                                     <input
//                                         type="text"
//                                         id="subject"
//                                         name="subject"
//                                         placeholder="Enter Subject"
//                                         value={formData.subject}
//                                         onChange={handleChange}
//                                         onBlur={handleBlur}
//                                         className="w-full md:w-80 lg:w-96 xl:w-full p-2.5 form-text:h-20 form-text:rounded-xl form-text:w-full form-text:p-3.5 form-text:text-2xl rounded bg-[#F1F2F3]"
//                                     />
//                                     {errors.subject && <span className="text-red-500 block mt-1">{errors.subject}</span>}
//                                 </div>
//                             </div>
//                             {/* <div className="flex flex-col md:flex-row gap-6 md:gap-14 mb-6 md:mb-12">
//                                 <div className="flex-1 md:mr-2 form-text:pt-9">
//                                     <label htmlFor="live-from" className="block mb-1 font-semibold text-lg md:text-lg form-text:text-3xl">Live From</label>
//                                     <select
//                                         id="live-from"
//                                         name="liveFrom"
//                                         value={formData.liveFrom}
//                                         onChange={handleChange}
//                                         onBlur={handleBlur}
//                                         className="w-full md:w-80 lg:w-96 xl:w-full font-semibold form-text:w-full form-text:h-20 form-text:rounded-xl p-2.5 form-text:p-3.5 form-text:text-2xl rounded bg-[#F1F2F3]"
//                                         style={{
//                                             appearance: 'none',
//                                             WebkitAppearance: 'none',
//                                             MozAppearance: 'none',
//                                             backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' fill=\'%23000000\' width=\'24px\' height=\'24px\'><path d=\'M7 10l5 5 5-5z\'/></svg>")',
//                                             backgroundRepeat: 'no-repeat',
//                                             backgroundPositionX: 'calc(100% - 10px)',
//                                             backgroundPositionY: 'center',
//                                             paddingRight: '30px'
//                                         }}
//                                     >
//                                         <option value="" className="font-semibold text-lg">Select one...</option>
//                                         <option value="option1" className='text-lg sm:text-lg'>Option 1</option>
//                                         <option value="option2" className='text-lg sm:text-lg'>Option 2</option>
//                                     </select>
//                                     {errors.liveFrom && <span className="text-red-500 block mt-1">{errors.liveFrom}</span>}
//                                 </div>
//                                 <div className="flex-1 form-text:pt-9">
//                                     <label htmlFor="interested-in" className="block mb-1 font-semibold text-lg md:text-lg form-text:text-3xl">What Are You Interested In?</label>
//                                     <select
//                                         id="interested-in"
//                                         name="interestedIn"
//                                         value={formData.interestedIn}
//                                         onChange={handleChange}
//                                         onBlur={handleBlur}
//                                         className="w-full md:w-80 lg:w-96 xl:w-full font-semibold form-text:w-full form-text:h-20 form-text:rounded-xl p-2.5 form-text:p-3.5 form-text:text-2xl rounded bg-[#F1F2F3]"
//                                         style={{
//                                             appearance: 'none',
//                                             WebkitAppearance: 'none',
//                                             MozAppearance: 'none',
//                                             backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' fill=\'%23000000\' width=\'24px\' height=\'24px\'><path d=\'M7 10l5 5 5-5z\'/></svg>")',
//                                             backgroundRepeat: 'no-repeat',
//                                             backgroundPositionX: 'calc(100% - 10px)',
//                                             backgroundPositionY: 'center',
//                                             paddingRight: '30px'
//                                         }}
//                                     >
//                                         <option value="" className="font-semibold text-lg">Select one...</option>
//                                         <option value="option1" className='text-lg sm:text-lg'>Option 1</option>
//                                         <option value="option2" className='text-lg sm:text-lg'>Option 2</option>
//                                     </select>
//                                     {errors.interestedIn && <span className="text-red-500 block mt-1">{errors.interestedIn}</span>}
//                                 </div>
//                             </div> */}
//                             <div className="pt-0.5 form-text:pt-12">
//                                 <label htmlFor="message" className="block mb-1 font-semibold text-lg md:text-lg form-text:text-3xl">Message</label>
//                                 <textarea
//                                     id="message"
//                                     name="message"
//                                     placeholder="Let us know more about your inquiry"
//                                     value={formData.message}
//                                     onChange={handleChange}
//                                     onBlur={handleBlur}
//                                     className="w-full p-3 form-text:p-5 rounded form-text:rounded-xl bg-[#F1F2F3] form-text:h-48 h-32 form-text:text-2xl resize-vertical"
//                                 ></textarea>
//                                 {errors.message && <span className="text-red-500 block mt-1">{errors.message}</span>}
//                             </div>
//                             <button
//                                 type="submit"
//                                 className="w-full md:w-32 h-12 form-text:w-48 form-text:h-20 bg-[#281E5D] text-white form-text:text-2xl rounded-full text-lg font-semibold mt-4"
//                             >
//                                 Submit
//                             </button>
//                         </div>
//                         {submitted && (
//                             <div className="bg-green-200 text-green-800 mt-8 px-4 py-2 mb-4 rounded-md">
//                                 Your request submitted successfully!
//                             </div>
//                         )}
//                     </form>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default Contact;

// import React, { useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';

// // aos
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// const Contact = () => {
//     const { t } = useTranslation();
//     useEffect(() => {
//         AOS.init();
//     }, []);

//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         mobile: '',
//         subject: '',
//         message: ''
//     });

//     const [errors, setErrors] = useState({});
//     const [submitted, setSubmitted] = useState(false);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value
//         });

//         if (errors[name]) {
//             setErrors({
//                 ...errors,
//                 [name]: ''
//             });
//         }
//     };

//     const handleBlur = (e) => {
//         const { name, value } = e.target;
//         let error = '';

//         if (!value) {
//             switch (name) {
//                 case 'name':
//                     error = 'Name is required';
//                     break;
//                 case 'email':
//                     error = 'Email is required';
//                     break;
//                 case 'mobile':
//                     error = 'Your Mobile number is required';
//                     break;
//                 case 'subject':
//                     error = 'Subject is required';
//                     break;
//                 case 'message':
//                     error = 'Message is required';
//                     break;
//                 default:
//                     break;
//             }
//         }

//         setErrors({
//             ...errors,
//             [name]: error
//         });
//     };

//     const validate = () => {
//         const newErrors = {};

//         if (!formData.name) newErrors.name = 'Name is required';
//         if (!formData.email) {
//             newErrors.email = 'Email is required';
//         } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
//             newErrors.email = 'Email is invalid';
//         }
//         if (!formData.mobile) newErrors.mobile = 'Your Mobile number is required';
//         if (!formData.subject) newErrors.subject = 'Subject is required';
//         if (!formData.message) newErrors.message = 'Message is required';

//         return newErrors;
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const validationErrors = validate();
//         if (Object.keys(validationErrors).length === 0) {
//             try {
//                 const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact/contacts`, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json'
//                     },
//                     body: JSON.stringify(formData)
//                 });

//                 if (response.ok) {
//                     console.log('Form submitted successfully');
//                     setFormData({
//                         name: '',
//                         email: '',
//                         mobile: '',
//                         subject: '',
//                         message: ''
//                     });
//                     setErrors({});
//                     setSubmitted(true);
//                 } else {
//                     console.error('Error submitting the form');
//                 }
//             } catch (error) {
//                 console.error('Error:', error);
//             }
//         } else {
//             setErrors(validationErrors);
//         }
//     };

//     useEffect(() => {
//         if (submitted) {
//             const timer = setTimeout(() => {
//                 setSubmitted(false);
//             }, 3000);
//             return () => clearTimeout(timer);
//         }
//     }, [submitted]);

//     return (
//         <>
//             <div className="relative z-1 flex justify-center items-center min-h-screen px-4 py-8 bg-[#F9FAFB]">
//                 <div className="w-full max-w-4xl bg-white shadow-lg p-8 rounded-lg">
//                     <div className="mb-6 text-center">
//                         <h1 className="font-semibold text-3xl xl:text-5xl mb-4">{t('contact.heading')}</h1>
//                     </div>
//                     <form onSubmit={handleSubmit} className="space-y-6">
//                         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                             <div>
//                                 <label htmlFor="name" className="block mb-1 font-semibold text-lg">Name</label>
//                                 <input
//                                     type="text"
//                                     id="name"
//                                     name="name"
//                                     placeholder="Enter your name"
//                                     value={formData.name}
//                                     onChange={handleChange}
//                                     onBlur={handleBlur}
//                                     className="w-full p-2.5 rounded bg-[#F1F2F3]"
//                                 />
//                                 {errors.name && <span className="text-red-500 block mt-1">{errors.name}</span>}
//                             </div>
//                             <div>
//                                 <label htmlFor="email" className="block mb-1 font-semibold text-lg">Email</label>
//                                 <input
//                                     type="email"
//                                     id="email"
//                                     name="email"
//                                     placeholder="Enter e-mail"
//                                     value={formData.email}
//                                     onChange={handleChange}
//                                     onBlur={handleBlur}
//                                     className="w-full p-2.5 rounded bg-[#F1F2F3]"
//                                 />
//                                 {errors.email && <span className="text-red-500 block mt-1">{errors.email}</span>}
//                             </div>
//                         </div>

//                         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                             <div>
//                                 <label htmlFor="mobile" className="block mb-1 font-semibold text-lg">Mobile Number</label>
//                                 <input
//                                     type="number"
//                                     id="mobile"
//                                     name="mobile"
//                                     placeholder="Enter Mobile Number"
//                                     value={formData.mobile}
//                                     onChange={handleChange}
//                                     onBlur={handleBlur}
//                                     className="w-full p-2.5 rounded bg-[#F1F2F3]"
//                                 />
//                                 {errors.mobile && <span className="text-red-500 block mt-1">{errors.mobile}</span>}
//                             </div>
//                             <div>
//                                 <label htmlFor="subject" className="block mb-1 font-semibold text-lg">Subject</label>
//                                 <input
//                                     type="text"
//                                     id="subject"
//                                     name="subject"
//                                     placeholder="Enter Subject"
//                                     value={formData.subject}
//                                     onChange={handleChange}
//                                     onBlur={handleBlur}
//                                     className="w-full p-2.5 rounded bg-[#F1F2F3]"
//                                 />
//                                 {errors.subject && <span className="text-red-500 block mt-1">{errors.subject}</span>}
//                             </div>
//                         </div>

//                         <div>
//                             <label htmlFor="message" className="block mb-1 font-semibold text-lg">Message</label>
//                             <textarea
//                                 id="message"
//                                 name="message"
//                                 placeholder="Let us know more about your inquiry"
//                                 value={formData.message}
//                                 onChange={handleChange}
//                                 onBlur={handleBlur}
//                                 className="w-full p-3 rounded bg-[#F1F2F3] resize-vertical h-32"
//                             ></textarea>
//                             {errors.message && <span className="text-red-500 block mt-1">{errors.message}</span>}
//                         </div>

//                         <button
//                             type="submit"
//                             className="w-full md:w-1/3 bg-[#281E5D] text-white py-3 rounded-full font-semibold text-lg mx-auto flex justify-center items-center"
//                         >
//                             Submit
//                         </button>

//                         {submitted && (
//                             <div className="bg-green-200 text-green-800 mt-8 px-4 py-2 mb-4 rounded-md text-center">
//                                 Your request has been submitted successfully!
//                             </div>
//                         )}
//                     </form>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default Contact;

//demo
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// aos
import AOS from 'aos';
import 'aos/dist/aos.css';

const Contact = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init();
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        subject: '',
        message: ''
    });

    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: ''
            });
        }
    };

    const handlePhoneChange = (value) => {
        setFormData({
            ...formData,
            mobile: value
        });
        console.log(value);

        if (errors.mobile) {
            setErrors({
                ...errors,
                mobile: ''
            });
            
        }
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        let error = '';

        if (!value) {
            switch (name) {
                case 'name':
                    error = 'Name is required';
                    break;
                case 'email':
                    error = 'Email is required';
                    break;
                case 'mobile':
                    error = 'Your Mobile number is required';
                    break;
                case 'subject':
                    error = 'Subject is required';
                    break;
                case 'message':
                    error = 'Message is required';
                    break;
                default:
                    break;
            }
        }

        setErrors({
            ...errors,
            [name]: error
        });
    };

    const validate = () => {
        const newErrors = {};

        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
        }
        if (!formData.mobile) newErrors.mobile = 'Your Mobile number is required';
        if (!formData.subject) newErrors.subject = 'Subject is required';
        if (!formData.message) newErrors.message = 'Message is required';

        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact/contacts`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                });

                if (response.ok) {
                    console.log('Form submitted successfully');
                    setFormData({
                        name: '',
                        email: '',
                        mobile: '',
                        subject: '',
                        message: ''
                    });
                    setErrors({});
                    setSubmitted(true);
                } else {
                    console.error('Error submitting the form');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    useEffect(() => {
        if (submitted) {
            const timer = setTimeout(() => {
                setSubmitted(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [submitted]);

    return (
        <>
            <div className="relative z-1 flex justify-center items-center min-h-screen px-4 py-8 bg-[#F9FAFB]">
                <div className="w-full max-w-4xl bg-white shadow-lg p-8 rounded-lg">
                    <div className="mb-6 text-center">
                        <h1 className="font-semibold text-3xl xl:text-5xl mb-4">{t('contact.heading')}</h1>
                    </div>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label htmlFor="name" className="block mb-1 font-semibold text-lg">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Enter your name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="w-full p-2.5 rounded bg-[#F1F2F3]"
                                />
                                {errors.name && <span className="text-red-500 block mt-1">{errors.name}</span>}
                            </div>
                            <div>
                                <label htmlFor="email" className="block mb-1 font-semibold text-lg">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Enter e-mail"
                                    value={formData.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="w-full p-2.5 rounded bg-[#F1F2F3]"
                                />
                                {errors.email && <span className="text-red-500 block mt-1">{errors.email}</span>}
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label htmlFor="mobile" className="block mb-1 font-semibold text-lg">Mobile Number</label>
                                {/* <PhoneInput
                                    country={'in'}
                                    value={formData.mobile}
                                    onChange={handlePhoneChange}
                                    inputStyle={{
                                        width: '100%',
                                        padding: '12px',
                                        borderRadius: '8px',
                                        backgroundColor: '#F1F2F3',
                                    }}
                                /> */}
                                <PhoneInput
    country={'in'}
    value={formData.mobile}
    onChange={handlePhoneChange}
    placeholder="Enter Mobile Number"
    type="number"
    id="mobile"
    name="mobile"
    onBlur={handleBlur}
    inputStyle={{
        width: '100%',
        padding: '20px',
        paddingLeft: '58px',  // Increase padding to make placeholder visible
        // borderRadius: '8px',
        backgroundColor: '#F1F2F3',
    }}
/>

                                {errors.mobile && <span className="text-red-500 block mt-1">{errors.mobile}</span>}
                            </div>
                            <div>
                                <label htmlFor="subject" className="block mb-1 font-semibold text-lg">Subject</label>
                                <input
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    placeholder="Enter Subject"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="w-full p-2.5 rounded bg-[#F1F2F3]"
                                />
                                {errors.subject && <span className="text-red-500 block mt-1">{errors.subject}</span>}
                            </div>
                        </div>

                        <div>
                            <label htmlFor="message" className="block mb-1 font-semibold text-lg">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                placeholder="Let us know more about your inquiry"
                                value={formData.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="w-full p-3 rounded bg-[#F1F2F3] resize-vertical h-32"
                            ></textarea>
                            {errors.message && <span className="text-red-500 block mt-1">{errors.message}</span>}
                        </div>

                        <button
                            type="submit"
                            className="w-full md:w-1/3 bg-[#281E5D] text-white py-3 rounded-full font-semibold text-lg mx-auto flex justify-center items-center"
                        >
                            Submit
                        </button>

                        {submitted && (
                            <div className="bg-green-200 text-green-800 mt-8 px-4 py-2 mb-4 rounded-md text-center">
                                Your request has been submitted successfully!
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
};

export default Contact;





