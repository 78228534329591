// import React, { useState } from 'react';
// import { useForm } from 'react-hook-form';
// import Header from '../header/header';
// // import Multipleheader from '../header/multiple-header';

// const Membership = () => {
//   const { register, handleSubmit, formState: { errors }, reset } = useForm();
//   const [isSubmitted, setIsSubmitted] = useState(false); // State to track form submission

//   const onSubmit = (data, event) => {
//     event.preventDefault(); // Prevent the default form submission behavior
    
//     console.log(data); // Replace with your form submission logic, e.g., API call
    
//     // Example of setting form submission success state
//     setIsSubmitted(true);

//     // Reset form fields after submission
//     reset();
  
//     // Clear success message after 3 seconds
//     setTimeout(() => {
//       setIsSubmitted(false);
//     }, 3000);
//   };

//   return (
//     <>
//     {/* <Multipleheader/> */}
//     <Header/>
//     <div className='py-24'>
    
//       <div className="max-w-3xl mx-auto p-6 bg-white rounded-xl-lg shadow-md">
//         <h1 className="text-2xl text-center font-bold mb-10">Register for Membership</h1>

//         <form onSubmit={handleSubmit(onSubmit)}>
//           {/* Personal Information Section */}
//           <section className="mb-6">
//             <h2 className="text-xl font-semibold bg-blue-950 text-white p-2 pl-8 rounded-xl">Personal Information</h2>
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
//               <div>
//                 <label className="block text-gray-700">Full Name</label>
//                 <input 
//                   type="text" 
//                   className={`p-2 border ${errors.fullName ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`} 
//                   {...register('fullName', { required: true })} 
//                 />
//                 {errors.fullName && <span className="text-red-500 text-sm">This field is required</span>}
//               </div>
//               <div>
//                 <label className="block text-gray-700">Address</label>
//                 <input 
//                   type="text" 
//                   className={`p-2 border ${errors.address ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`} 
//                   {...register('address', { required: true })} 
//                 />
//                 {errors.address && <span className="text-red-500 text-sm">This field is required</span>}
//               </div>

//               <div className="flex items-center space-x-2">
//                 <div>
//                   <label className="block text-sm text-gray-700">Date of Birth</label>
//                   <input 
//                     type="text" 
//                     placeholder="Month" 
//                     className={`p-2 border ${errors.dobMonth ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`} 
//                     {...register('dobMonth', { required: true })} 
//                   />
//                   {errors.dobMonth && <span className="text-red-500 text-sm">This field is required</span>}
//                 </div>
//                 <div>
//                   <label className="block text-gray-700">&nbsp;</label>
//                   <input 
//                     type="text" 
//                     placeholder="Day" 
//                     className={`p-2 border ${errors.dobDay ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`} 
//                     {...register('dobDay', { required: true })} 
//                   />
//                   {errors.dobDay && <span className="text-red-500 text-sm">This field is required</span>}
//                 </div>
//                 <div>
//                   <label className="block text-gray-700">&nbsp;</label>
//                   <input 
//                     type="text" 
//                     placeholder="Year" 
//                     className={`p-2 border ${errors.dobYear ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`} 
//                     {...register('dobYear', { required: true })} 
//                   />
//                   {errors.dobYear && <span className="text-red-500 text-sm">This field is required</span>}
//                 </div>
//               </div>

//               <div>
//                 <label className="block text-gray-700">Gender</label>
//                 <div className="flex items-center mt-2 space-x-4">
//                   <label className="flex items-center">
//                     <input 
//                       type="radio" 
//                       name="gender" 
//                       value="male" 
//                       className="mr-2" 
//                       {...register('gender', { required: true })} 
//                     />
//                     Male
//                   </label>
//                   <label className="flex items-center">
//                     <input 
//                       type="radio" 
//                       name="gender" 
//                       value="female" 
//                       className="mr-2" 
//                       {...register('gender', { required: true })} 
//                     />
//                     Female
//                   </label>
//                 </div>
//                 {errors.gender && <span className="text-red-500 text-sm">This field is required</span>}
//               </div>

//               <div>
//                 <label className="block text-gray-700">Phone Number</label>
//                 <input 
//                   type="text" 
//                   className={`p-2 border ${errors.phoneNumber ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`} 
//                   {...register('phoneNumber', { required: true })} 
//                 />
//                 {errors.phoneNumber && <span className="text-red-500 text-sm">This field is required</span>}
//               </div>
//               <div>
//                 <label className="block text-gray-700">E-mail</label>
//                 <input 
//                   type="email" 
//                   className={`p-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`} 
//                   {...register('email', { required: true })} 
//                 />
//                 {errors.email && <span className="text-red-500 text-sm">This field is required</span>}
//               </div>
//             </div>
//           </section>

//           {/* Membership Type Section */}
//           <section className="mb-6">
//             <h2 className="text-xl font-semibold bg-blue-950 text-white p-2 pl-8 rounded-xl">Membership Type</h2>
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
//               <div className="flex flex-wrap gap-[30%]">
//                 <label className="flex items-center">
//                   <input type="checkbox" className="mr-2" {...register('individualMembership')} />
//                   இயக்குனர்கள் / Directors 
//                 </label>
//                 <label className="flex items-center">
//                   <input type="checkbox" className="mr-2" {...register('familyMembership')} />
//                   ஆலோசகர்கள் / advisors
//                 </label>
//               </div>
//               <div className="flex flex-wrap gap-[8%]">
//                 <label className="flex items-center">
//                   <input type="checkbox" className="mr-2" {...register('studentMembership')} />
//                   ஆயுட்கால உறுப்பினர்கள் / Lifetime membership
//                 </label>
//                 <label className="flex items-center">
//                   <input type="checkbox" className="mr-2" {...register('seniorCitizenMembership')} />
//                   உறுப்பினர்கள் / membership
//                 </label>
//               </div>
//             </div>
//           </section>

//           {/* Additional Information Section */}
//           <section className="mb-6">
//             <h2 className="text-xl font-semibold bg-blue-950 text-white p-2 pl-8 rounded-xl">Additional Information (Optional)</h2>
//             <p className="mt-4 text-gray-700">
//             Please indicate your interests or area where you would like to contribute to the organization.
//             </p>
//             <textarea
//               className="w-full p-2 border border-gray-300 rounded-xl mt-2"
//               rows="4"
//               {...register('additionalInformation')}
//             />
//           </section>

//           <div className="flex justify-center">
//             <button type="submit" className="p-2 w-1/4 bg-blue-950 text-white rounded-full">
//               Submit
//             </button>
//           </div>
//         </form>
//         {isSubmitted && (
//           <div className="bg-green-200 mt-10 text-green-800 p-3 mb-6 rounded-md text-center">
//             Your registration is successful!
//           </div>
//         )}
//       </div>
//     </div>
//     </>
//   );
// };

// export default Membership;
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Header from '../header/header';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
// import Multipleheader from '../header/multiple-header';

const Membership = () => {
  const { register, handleSubmit, formState: { errors }, reset, getValues, trigger } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track form submission
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = (data, event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    console.log(data); // Replace with your form submission logic, e.g., API call

    // Example of setting form submission success state
    setIsSubmitted(true);

    // Reset form fields after submission
    reset();

    // Clear success message after 3 seconds
    setTimeout(() => {
      setIsSubmitted(false);
    }, 3000);
  };

  return (
    <>
      {/* <Multipleheader/> */}
      <Header />
      <div className='py-24'>

        <div className="max-w-3xl mx-auto p-6 bg-white rounded-xl-lg shadow-md">
          <h1 className="text-2xl text-center font-bold mb-10">Register for Membership</h1>

          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Personal Information Section */}
            <section className="mb-6">
              <h2 className="text-xl font-semibold bg-blue-950 text-white p-2 pl-8 rounded-xl">Personal Information</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <div>
                  <label className="block text-gray-700">Full Name</label>
                  <input
                    type="text"
                    className={`p-2 border ${errors.fullName ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`}
                    {...register('fullName', { required: true })}
                  />
                  {errors.fullName && <span className="text-red-500 text-sm">This field is required</span>}
                </div>
                <div>
                  <label className="block text-gray-700">Address</label>
                  <input
                    type="text"
                    className={`p-2 border ${errors.address ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`}
                    {...register('address', { required: true })}
                  />
                  {errors.address && <span className="text-red-500 text-sm">This field is required</span>}
                </div>

                <div className="flex items-center space-x-2">
                  <div>
                    <label className="block text-sm text-gray-700">Date of Birth</label>
                    <input
                      type="text"
                      placeholder="Month"
                      className={`p-2 border ${errors.dobMonth ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`}
                      {...register('dobMonth', { required: true })}
                    />
                    {errors.dobMonth && <span className="text-red-500 text-sm">This field is required</span>}
                  </div>
                  <div>
                    <label className="block text-gray-700">&nbsp;</label>
                    <input
                      type="text"
                      placeholder="Day"
                      className={`p-2 border ${errors.dobDay ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`}
                      {...register('dobDay', { required: true })}
                    />
                    {errors.dobDay && <span className="text-red-500 text-sm">This field is required</span>}
                  </div>
                  <div>
                    <label className="block text-gray-700">&nbsp;</label>
                    <input
                      type="text"
                      placeholder="Year"
                      className={`p-2 border ${errors.dobYear ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`}
                      {...register('dobYear', { required: true })}
                    />
                    {errors.dobYear && <span className="text-red-500 text-sm">This field is required</span>}
                  </div>
                </div>

                <div>
                  <label className="block text-gray-700">Gender</label>
                  <div className="flex items-center mt-2 space-x-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="gender"
                        value="male"
                        className="mr-2"
                        {...register('gender', { required: true })}
                      />
                      Male
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="gender"
                        value="female"
                        className="mr-2"
                        {...register('gender', { required: true })}
                      />
                      Female
                    </label>
                  </div>
                  {errors.gender && <span className="text-red-500 text-sm">This field is required</span>}
                </div>

                <div>
                  <label className="block text-gray-700">Phone Number</label>
                  <input
                    type="text"
                    className={`p-2 border ${errors.phoneNumber ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`}
                    {...register('phoneNumber', { required: true })}
                  />
                  {errors.phoneNumber && <span className="text-red-500 text-sm">This field is required</span>}
                </div>
                <div>
                  <label className="block text-gray-700">E-mail</label>
                  <input
                    type="email"
                    className={`p-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`}
                    {...register('email', { required: true })}
                  />
                  {errors.email && <span className="text-red-500 text-sm">This field is required</span>}
                </div>
                {/* Password field */}
                <div>
                  <label className="block text-gray-700">Password</label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className={`p-2 border ${errors.password ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`}
                      {...register('password', {
                        required: "Password is required",
                        minLength: { value: 6, message: "Password must be at least 6 characters" }
                      })}
                      onChange={() => trigger('confirmPassword')} // Trigger re-validation on password change
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute top-1/2 right-3 transform -translate-y-1/2 focus:outline-none"
                    >
                      {showPassword ? <MdVisibility className="text-gray-400" /> : <MdVisibilityOff className="text-gray-400" />}
                    </button>
                  </div>
                  {errors.password && <span className="text-red-500 text-sm">{errors.password.message}</span>}
                </div>

                {/* Confirm Password field */}
                <div>
                  <label className="block text-gray-700">Confirm Password</label>
                  <div className="relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className={`p-2 border ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'} rounded-xl w-full`}
                      {...register('confirmPassword', {
                        required: "Please confirm your password",
                        validate: (value) =>
                          value === getValues('password') || "Passwords do not match",
                      })}
                    />
                    <button
                      type="button"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      className="absolute top-1/2 right-3 transform -translate-y-1/2 focus:outline-none"
                    >
                      {showConfirmPassword ? <MdVisibility className="text-gray-400" /> : <MdVisibilityOff className="text-gray-400" />}
                    </button>
                  </div>
                  {errors.confirmPassword && <span className="text-red-500 text-sm">{errors.confirmPassword.message}</span>}
                </div>
              </div>
            </section>

            {/* Membership Type Section */}
            <section className="mb-6">
              <h2 className="text-xl font-semibold bg-blue-950 text-white p-2 pl-8 rounded-xl">Membership Type</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <div className="flex flex-wrap gap-[30%]">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      value="directors"
                      {...register('membershipType', { required: true })} // Use 'membershipType' field
                      className="mr-2"
                    />
                    இயக்குனர்கள் / Directors
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      value="advisors"
                      {...register('membershipType', { required: true })} // Use 'membershipType' field
                      className="mr-2"
                    />
                    ஆலோசகர்கள் / Advisors
                  </label>
                </div>
                <div className="flex flex-wrap gap-[8%]">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      value="lifetimeMembership"
                      {...register('membershipType', { required: true })} // Use 'membershipType' field
                      className="mr-2"
                    />
                    ஆயுட்கால உறுப்பினர்கள் / Lifetime membership
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      value="membership"
                      {...register('membershipType', { required: true })} // Use 'membershipType' field
                      className="mr-2"
                    />
                    உறுப்பினர்கள் / Membership
                  </label>
                </div>
              </div>
              {errors.membershipType && (
                <span className="text-red-500 text-sm">Please select a membership type</span>
              )}
            </section>


            {/* Additional Information Section */}
            <section className="mb-6">
              <h2 className="text-xl font-semibold bg-blue-950 text-white p-2 pl-8 rounded-xl">Additional Information (Optional)</h2>
              <p className="mt-4 text-gray-700">
                Please indicate any specific interests, skills, or areas of expertise you would like to contribute to the organization.
              </p>
              <textarea
                className="w-full p-2 border border-gray-300 rounded-xl mt-2"
                rows="4"
                {...register('additionalInformation')}
              />
            </section>

            <div className="flex justify-center">
              <button type="submit" className="p-2 w-1/4 bg-blue-950 text-white rounded-full">
                Submit
              </button>
            </div>
          </form>
          {isSubmitted && (
            <div className="bg-green-200 mt-10 text-green-800 p-3 mb-6 rounded-md text-center">
              Your registration is successful!
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Membership;
